button {
  font-size: 1.2em;
  padding: 8px 12px;
  color: white;
  border: solid 1px var(--color-border);
  border-radius: 4px;
}
a {
  text-decoration: none;
  color: var(--color-primary);
}
a:hover,
a:active,
a:focus {
  color: var(--color-primary);
}
a:active {
  translate: transformY(1px);
}
a:visited {
  color: var(--color-primary);
}