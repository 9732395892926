/* 
@font-face {
  font-family: "Rogliano Black Italic";
  src: url("/assets/fonts/Rogliano-Black-Italic.otf") format("opentype");
  font-style: italic;
}
@font-face {
  font-family: "Rogliano Black";
  src: url("/assets/fonts/Rogliano-Black.otf") format("opentype");
  font-style: normal;
}
@font-face {
  font-family: "Rogliano ExtraBold Italic";
  src: url("/assets/fonts/Rogliano-ExtraBold-Italic.otf") format("opentype");
  font-style: italic;
}
@font-face {
  font-family: "Rogliano ExtraBold";
  src: url("/assets/fonts/Rogliano-ExtraBold.otf") format("opentype");
  font-style: normal;
}
@font-face {
  font-family: "Rogliano Bold Italic";
  src: url("/assets/fonts/Rogliano-Bold-Italic.otf") format("opentype");
  font-style: italic;
}
@font-face {
  font-family: "Rogliano Bold";
  src: url("/assets/fonts/Rogliano-Bold.otf") format("opentype");
  font-style: normal;
}
@font-face {
  font-family: "Rogliano Italic";
  src: url("/assets/fonts/Rogliano-Italic.otf") format("opentype");
  font-style: italic;
}
@font-face {
  font-family: "Rogliano";
  src: url("/assets/fonts/Rogliano-Regular.otf") format("opentype");
  font-style: normal;
}
@font-face {
  font-family: "Rogliano Light Italic";
  src: url("/assets/fonts/Rogliano-Light-Italic.otf") format("opentype");
  font-style: italic;
}
@font-face {
  font-family: "Rogliano Light";
  src: url("/assets/fonts/Rogliano-Light.otf") format("opentype");
  font-style: normal;
}
@font-face {
  font-family: "Rogliano Thin Italic";
  src: url("/assets/fonts/Rogliano-Thin-Italic.otf") format("opentype");
  font-style: italic;
}
@font-face {
  font-family: "Rogliano Light";
  src: url("/assets/fonts/Rogliano-Thin.otf") format("opentype");
  font-style: normal;
}
@font-face {
  font-family: "Rogliano UltraLight Italic";
  src: url("/assets/fonts/Rogliano-UltraLight-Italic.otf") format("opentype");
  font-style: italic;
}
@font-face {
  font-family: "Rogliano UltraLight";
  src: url("/assets/fonts/Rogliano-UltraLight.otf") format("opentype");
  font-style: normal;
}
@font-face {
  font-family: "Sofia Pro Black Italic";
  src: url("/assets/fonts/Sofia-Pro-Black-Italic.otf") format("opentype");
  font-style: italic;
}
@font-face {
  font-family: "Sofia Pro Black";
  src: url("/assets/fonts/Sofia-Pro-Black.otf") format("opentype");
  font-style: normal;
}
@font-face {
  font-family: "Sofia Pro Bold Italic";
  src: url("/assets/fonts/Sofia-Pro-Bold-Italic.otf") format("opentype");
  font-style: italic;
}
@font-face {
  font-family: "Sofia Pro Bold";
  src: url("/assets/fonts/Sofia-Pro-Bold.otf") format("opentype");
  font-style: normal;
}
@font-face {
  font-family: "Sofia-Pro Semi Bold Italic";
  src: url("/assets/fonts/Sofia-Pro-Semi-Bold-Italic.otf") format("opentype");
  font-style: italic;
}
@font-face {
  font-family: "Sofia-Pro Semi Bold";
  src: url("/assets/fonts/Sofia-Pro-Semi-Bold.otf") format("opentype");
  font-style: normal;
}
@font-face {
  font-family: "Sofia Pro Medium Italic";
  src: url("/assets/fonts/Sofia-Pro-Medium-Italic.otf") format("opentype");
  font-style: italic;
}
@font-face {
  font-family: "Sofia Pro Medium";
  src: url("/assets/fonts/Sofia-Pro-Medium.otf") format("opentype");
  font-style: normal;
}
@font-face {
  font-family: "Sofia Pro Italic";
  src: url("/assets/fonts/Sofia-Pro-Regular-Italic.otf") format("opentype");
  font-style: italic;
}
@font-face {
  font-family: "Sofia Pro";
  src: url("/assets/fonts/Sofia-Pro-Regular.otf") format("opentype");
  font-style: normal;
}
@font-face {
  font-family: "Sofia Pro Light Italic";
  src: url("/assets/fonts/Sofia-Pro-Light-Italic.otf") format("opentype");
  font-style: italic;
}
@font-face {
  font-family: "Sofia Pro Light";
  src: url("/assets/fonts/Sofia-Pro-Light.otf") format("opentype");
  font-style: normal;
}
@font-face {
  font-family: "Sofia Pro Extra Light Italic";
  src: url("/assets/fonts/Sofia-Pro-Extra-Light-Italic.otf") format("opentype");
  font-style: italic;
}
@font-face {
  font-family: "Sofia Pro Extra Light";
  src: url("/assets/fonts/Sofia-Pro-Extra-Light.otf") format("opentype");
  font-style: normal;
}
 */

/* 
@font-face {
  font-family: "Rogliano Black Italic";
  src: url("/assets/fonts/Rogliano-Black-Italic.otf") format("opentype");
  font-style: italic;
}
@font-face {
  font-family: "Rogliano Black";
  src: url("/assets/fonts/Rogliano-Black.otf") format("opentype");
  font-style: normal;
}
@font-face {
  font-family: "Rogliano ExtraBold Italic";
  src: url("/assets/fonts/Rogliano-ExtraBold-Italic.otf") format("opentype");
  font-style: italic;
}
@font-face {
  font-family: "Rogliano ExtraBold";
  src: url("/assets/fonts/Rogliano-ExtraBold.otf") format("opentype");
  font-style: normal;
}
@font-face {
  font-family: "Rogliano Bold Italic";
  src: url("/assets/fonts/Rogliano-Bold-Italic.otf") format("opentype");
  font-style: italic;
}
@font-face {
  font-family: "Rogliano Bold";
  src: url("/assets/fonts/Rogliano-Bold.otf") format("opentype");
  font-style: normal;
}
@font-face {
  font-family: "Rogliano Italic";
  src: url("/assets/fonts/Rogliano-Italic.otf") format("opentype");
  font-style: italic;
}
@font-face {
  font-family: "Rogliano";
  src: url("/assets/fonts/Rogliano-Regular.otf") format("opentype");
  font-style: normal;
}
@font-face {
  font-family: "Rogliano Light Italic";
  src: url("/assets/fonts/Rogliano-Light-Italic.otf") format("opentype");
  font-style: italic;
}
@font-face {
  font-family: "Rogliano Light";
  src: url("/assets/fonts/Rogliano-Light.otf") format("opentype");
  font-style: normal;
}
@font-face {
  font-family: "Rogliano Thin Italic";
  src: url("/assets/fonts/Rogliano-Thin-Italic.otf") format("opentype");
  font-style: italic;
}
@font-face {
  font-family: "Rogliano Light";
  src: url("/assets/fonts/Rogliano-Thin.otf") format("opentype");
  font-style: normal;
}
@font-face {
  font-family: "Rogliano UltraLight Italic";
  src: url("/assets/fonts/Rogliano-UltraLight-Italic.otf") format("opentype");
  font-style: italic;
}
@font-face {
  font-family: "Rogliano UltraLight";
  src: url("/assets/fonts/Rogliano-UltraLight.otf") format("opentype");
  font-style: normal;
}
@font-face {
  font-family: "Sofia Pro Black Italic";
  src: url("/assets/fonts/Sofia-Pro-Black-Italic.otf") format("opentype");
  font-style: italic;
}
@font-face {
  font-family: "Sofia Pro Black";
  src: url("/assets/fonts/Sofia-Pro-Black.otf") format("opentype");
  font-style: normal;
}
@font-face {
  font-family: "Sofia Pro Bold Italic";
  src: url("/assets/fonts/Sofia-Pro-Bold-Italic.otf") format("opentype");
  font-style: italic;
}
@font-face {
  font-family: "Sofia Pro Bold";
  src: url("/assets/fonts/Sofia-Pro-Bold.otf") format("opentype");
  font-style: normal;
}
@font-face {
  font-family: "Sofia-Pro Semi Bold Italic";
  src: url("/assets/fonts/Sofia-Pro-Semi-Bold-Italic.otf") format("opentype");
  font-style: italic;
}
@font-face {
  font-family: "Sofia-Pro Semi Bold";
  src: url("/assets/fonts/Sofia-Pro-Semi-Bold.otf") format("opentype");
  font-style: normal;
}
@font-face {
  font-family: "Sofia Pro Medium Italic";
  src: url("/assets/fonts/Sofia-Pro-Medium-Italic.otf") format("opentype");
  font-style: italic;
}
@font-face {
  font-family: "Sofia Pro Medium";
  src: url("/assets/fonts/Sofia-Pro-Medium.otf") format("opentype");
  font-style: normal;
}
@font-face {
  font-family: "Sofia Pro Italic";
  src: url("/assets/fonts/Sofia-Pro-Regular-Italic.otf") format("opentype");
  font-style: italic;
}
@font-face {
  font-family: "Sofia Pro";
  src: url("/assets/fonts/Sofia-Pro-Regular.otf") format("opentype");
  font-style: normal;
}
@font-face {
  font-family: "Sofia Pro Light Italic";
  src: url("/assets/fonts/Sofia-Pro-Light-Italic.otf") format("opentype");
  font-style: italic;
}
@font-face {
  font-family: "Sofia Pro Light";
  src: url("/assets/fonts/Sofia-Pro-Light.otf") format("opentype");
  font-style: normal;
}
@font-face {
  font-family: "Sofia Pro Extra Light Italic";
  src: url("/assets/fonts/Sofia-Pro-Extra-Light-Italic.otf") format("opentype");
  font-style: italic;
}
@font-face {
  font-family: "Sofia Pro Extra Light";
  src: url("/assets/fonts/Sofia-Pro-Extra-Light.otf") format("opentype");
  font-style: normal;
}
 */


@font-face {
  font-family: "Rogliano Black";
  src: url("/assets/fonts/Rogliano-Black-Italic.otf") format("opentype");
  font-style: italic;
}
@font-face {
  font-family: "Rogliano Black";
  src: url("/assets/fonts/Rogliano-Black.otf") format("opentype");
  font-style: normal;
}
@font-face {
  font-family: "Rogliano ExtraBold";
  src: url("/assets/fonts/Rogliano-ExtraBold-Italic.otf") format("opentype");
  font-style: italic;
}
@font-face {
  font-family: "Rogliano ExtraBold";
  src: url("/assets/fonts/Rogliano-ExtraBold.otf") format("opentype");
  font-style: normal;
}
@font-face {
  font-family: "Rogliano Bold";
  src: url("/assets/fonts/Rogliano-Bold-Italic.otf") format("opentype");
  font-style: italic;
}
@font-face {
  font-family: "Rogliano Bold";
  src: url("/assets/fonts/Rogliano-Bold.otf") format("opentype");
  font-style: normal;
}
@font-face {
  font-family: "Rogliano";
  src: url("/assets/fonts/Rogliano-Italic.otf") format("opentype");
  font-style: italic;
}
@font-face {
  font-family: "Rogliano";
  src: url("/assets/fonts/Rogliano-Regular.otf") format("opentype");
  font-style: normal;
}
@font-face {
  font-family: "Rogliano Light";
  src: url("/assets/fonts/Rogliano-Light-Italic.otf") format("opentype");
  font-style: italic;
}
@font-face {
  font-family: "Rogliano Light";
  src: url("/assets/fonts/Rogliano-Light.otf") format("opentype");
  font-style: normal;
}
@font-face {
  font-family: "Rogliano Thin";
  src: url("/assets/fonts/Rogliano-Thin-Italic.otf") format("opentype");
  font-style: italic;
}
@font-face {
  font-family: "Rogliano Light";
  src: url("/assets/fonts/Rogliano-Thin.otf") format("opentype");
  font-style: normal;
}
@font-face {
  font-family: "Rogliano UltraLight";
  src: url("/assets/fonts/Rogliano-UltraLight-Italic.otf") format("opentype");
  font-style: italic;
}
@font-face {
  font-family: "Rogliano UltraLight";
  src: url("/assets/fonts/Rogliano-UltraLight.otf") format("opentype");
  font-style: normal;
}
@font-face {
  font-family: "Sofia Pro Black";
  src: url("/assets/fonts/Sofia-Pro-Black-Italic.otf") format("opentype");
  font-style: italic;
}
@font-face {
  font-family: "Sofia Pro Black";
  src: url("/assets/fonts/Sofia-Pro-Black.otf") format("opentype");
  font-style: normal;
}
@font-face {
  font-family: "Sofia Pro Bold";
  src: url("/assets/fonts/Sofia-Pro-Bold-Italic.otf") format("opentype");
  font-style: italic;
}
@font-face {
  font-family: "Sofia Pro Bold";
  src: url("/assets/fonts/Sofia-Pro-Bold.otf") format("opentype");
  font-style: normal;
}
@font-face {
  font-family: "Sofia-Pro Semi Bold";
  src: url("/assets/fonts/Sofia-Pro-Semi-Bold-Italic.otf") format("opentype");
  font-style: italic;
}
@font-face {
  font-family: "Sofia-Pro Semi Bold";
  src: url("/assets/fonts/Sofia-Pro-Semi-Bold.otf") format("opentype");
  font-style: normal;
}
@font-face {
  font-family: "Sofia Pro Medium";
  src: url("/assets/fonts/Sofia-Pro-Medium-Italic.otf") format("opentype");
  font-style: italic;
}
@font-face {
  font-family: "Sofia Pro Medium";
  src: url("/assets/fonts/Sofia-Pro-Medium.otf") format("opentype");
  font-style: normal;
}
@font-face {
  font-family: "Sofia Pro";
  src: url("/assets/fonts/Sofia-Pro-Regular-Italic.otf") format("opentype");
  font-style: italic;
}
@font-face {
  font-family: "Sofia Pro";
  src: url("/assets/fonts/Sofia-Pro-Regular.otf") format("opentype");
  font-style: normal;
}
@font-face {
  font-family: "Sofia Pro Light";
  src: url("/assets/fonts/Sofia-Pro-Light-Italic.otf") format("opentype");
  font-style: italic;
}
@font-face {
  font-family: "Sofia Pro Light";
  src: url("/assets/fonts/Sofia-Pro-Light.otf") format("opentype");
  font-style: normal;
}
@font-face {
  font-family: "Sofia Pro Extra Light";
  src: url("/assets/fonts/Sofia-Pro-Extra-Light-Italic.otf") format("opentype");
  font-style: italic;
}
@font-face {
  font-family: "Sofia Pro Extra Light";
  src: url("/assets/fonts/Sofia-Pro-Extra-Light.otf") format("opentype");
  font-style: normal;
}

 