html,
:root {
  /* primary color */
  --purple-blue: #605dec;
  --purple-blue-a10: rgba(96, 93, 236, 0.1);
  --purple-blue-a15: rgba(96, 93, 236, 0.15);
  --purple-blue-a20: rgba(96, 93, 236, 0.2);
  --purple-blue-a30: rgba(96, 93, 236, 0.3);
  --purple-blue-a40: rgba(96, 93, 236, 0.4);
  --purple-blue-a50: rgba(96, 93, 236, 0.5);
  --purple-blue-a60: rgba(96, 93, 236, 0.6);
  --purple-blue-a70: rgba(96, 93, 236, 0.7);
  --purple-blue-a80: rgba(96, 93, 236, 0.8);
  --purple-blue-a90: rgba(96, 93, 236, 0.9);
  --purple-blue-dark: #1513a0;
  --purple-blue-extra-light: #e9e8fc;
  --purple-blue-gradient: linear-gradient(to bottom, #9d8cf2 2.08%, #707ff2 56.77%, #266af2 100%);

  /* secondary color */
  --turqoise: #5cd6c0;
  --turqoise-a10: rgba(92, 214, 192, 0.1);
  --turqoise-a20: rgba(92, 214, 192, 0.2);
  --turqoise-a30: rgba(92, 214, 192, 0.3);
  --turqoise-a40: rgba(92, 214, 192, 0.4);
  --turqoise-a50: rgba(92, 214, 192, 0.5);
  --turqoise-a60: rgba(92, 214, 192, 0.6);
  --turqoise-a70: rgba(92, 214, 192, 0.7);
  --turqoise-a80: rgba(92, 214, 192, 0.8);
  --turqoise-a90: rgba(92, 214, 192, 0.9);

  /* accent color */
  --pink1: #eb568c;
  --pink2: #ed5e76;
  --pink-gradient: linear-gradient(to bottom, var(--pink1) 0%, var(--pink2) 100%);

  /* ui colors */
  --pure-white: #ffffff;
  --color-primary: var(--purple-blue);
  --color-primary-dark: var(--purple-blue-dark);
  --color-primary-light: var(--purple-blue-extra-light);
  --color-primary-lightest: #f6f6fe;
  --color-primary-a10: var(--purple-blue-a10);
  --color-primary-a15: var(--purple-blue-a15);
  --color-primary-a20: var(--purple-blue-a20);
  --color-primary-a30: var(--purple-blue-a30);
  --color-primary-a40: var(--purple-blue-a40);
  --color-primary-a50: var(--purple-blue-a50);
  --color-primary-a60: var(--purple-blue-a60);
  --color-primary-a70: var(--purple-blue-a70);
  --color-primary-a80: var(--purple-blue-a80);
  --color-primary-a90: var(--purple-blue-a90);

  --color-secondary: var(--turqoise);
  --color-secondary-a10: var(--turqoise-a10);
  --color-secondary-a15: var(--turqoise-a15);
  --color-secondary-a20: var(--turqoise-a20);
  --color-secondary-a30: var(--turqoise-a30);
  --color-secondary-a40: var(--turqoise-a40);
  --color-secondary-a50: var(--turqoise-a50);
  --color-secondary-a60: var(--turqoise-a60);
  --color-secondary-a70: var(--turqoise-a70);
  --color-secondary-a80: var(--turqoise-a80);
  --color-secondary-a90: var(--turqoise-a90);

  --color-accent: var(--pink1);

  --background-color-primary: var(--pure-white);
  --background-color-dark: #27273f;
  --background-color-very-light-blue: #cbd4e6;
  --background-color-lightest-blue: #f6f6fe;
  --background-color-inactive: rgba(203, 212, 230, 0.3);

  --border-color: #acb5bd;
  --icon-color-default: #5c5f62;

  --inactive-background-color: rgba(203, 212, 230, 0.3);
  --inactive-color: rgba(203, 212, 230, 0.7);

  /* font */
  --font-color-less-dark: #495057;
  --font-color-dark: #121417;
  --font-color-primary: var(--font-color-dark);
  --font-color-subdued: #7c8db0;
  --font-color-subdued-header: #6e7491;

  /* red */
  --red-destructive: #eb5757;
  --red-extra-light: #fdefef;
  /* gold */
  --star-gold: #e5a800;

  /* success */
  --success-100: #ddfded;
  --success-200: #0ac295;
  --success-300: #09a57f;
  --success-400: #078364;
  --success-500: #027357;
  /* warning */
  --warning-100: #fef7b9;
  --warning-200: #ffda6c;
  --warning-300: #ffb400;
  --warning-400: #e07c02;
  --warning-500: #c33e01;
  /* error */
  --error-100: #fcd2cf;
  --error-200: #eb5757;
  --error-300: #df320c;
  --error-400: #c61a0b;
  --error-500: #ae0a0a;
  /* neutral */
  --neutral-100: #f8f9fa;
  --neutral-200: #e4e8ee;
  --neutral-300: #dde2e5;
  --neutral-400: #acb5bd;
  --neutral-500: #6a7381;
  --neutral-600: #49505a;
  --neutral-700: #1f2329;

  --grey-linear-gradient: linear-gradient(to bottom, rgba(186, 191, 195, 1) 0%, rgba(187, 191, 195, 1) 100%);
}

html,
#root,
:root {
  /* fonts */
  --font-primary: "Sofia Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
    "Open Sans", "Helvetica Neue", sans-serif;
  --font-primary-bold: "Sofia Pro Bold";
  --font-primary-light: "Sofia Pro Light";
  --font-heading: "Rogliano", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
    "Open Sans", "Helvetica Neue", arial;
  --font-heading-bold: "Rogliano Bold";
  --font-heading-light: "Rogliano Light";

  /* UI drop shadow - filter */
  /* --elevation-0: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.2));
  --elevation-1: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.05)),
                 drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.25));
  --elevation-2: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.2)),
                 drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.1));
  --elevation-3: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.15)),
                 drop-shadow(0px 1px 4px rgba(7, 4, 146, 0.1)),
                 drop-shadow(0px 12px 40px rgba(6, 47, 125, 0.05)),
                 drop-shadow(0px 8px 14px rgba(27, 59, 119, 0.05)); */

  /* UI box shadow */
  --elevation-0: 0px 1px 1px rgba(203, 212, 230, 0.1);
  --elevation-1: 0px 2px 4px rgba(203, 212, 230, 0.25);
  --elevation-2: 0px 4px 4px rgba(121, 120, 159, 0.1), 0px 1px 5px rgba(121, 120, 159, 0.1);
  --elevation-3: 0px 6px 12px rgba(121, 120, 159, 0.1), 0px 4px 4px rgba(96, 93, 236, 0.1),
    0px 2px 2px rgba(96, 93, 236, 0.1);
  --elevation-4: 0px 12px 24px rgba(121, 120, 159, 0.1), 0px 6px 12px rgba(121, 120, 159, 0.1),
    0px 4px 4px rgba(96, 93, 236, 0.1), 0px 2px 2px rgba(96, 93, 236, 0.1);
  --elevation-5: 0px 40px 64px rgba(121, 120, 159, 0.1), 0px 24px 32px rgba(121, 120, 159, 0.1),
    0px 16px 16px rgba(121, 120, 159, 0.1), 0px 8px 8px rgba(121, 120, 159, 0.1), 0px 4px 4px rgba(96, 93, 236, 0.1),
    0px 2px 2px rgba(96, 93, 236, 0.1);
  /* --elevation-4: 0px 0px 4px rgba(0, 0, 0, 0.2),
                 0px 2px 4px rgba(7, 4, 146, 0.1),
                 0px 24px 60px rgba(6, 47, 125, 0.05),
                 0px 12px 24px rgba(27, 59, 119, 0.05);
  --card-elevation-1: 0px 2px 1px rgba(0, 0, 0, 0.05),
                      0px 0px 1px rgba(0, 0, 0, 0.25);
  --card-elevation-2: 0px 0px 2px rgba(0, 0, 0, 0.2),
                      0px 2px 10px rgba(0, 0, 0, 0.1);
  --card-elevation-3: 0px 2px 4px rgba(7, 4, 146, 0.1),
                      0px 24px 60px rgba(6, 47, 125, 0.05),
                      0px 12px 24px rgba(27, 59, 119, 0.05);
  --box-elevation-1: 0px 2px 1px rgba(0, 0, 0, 0.05),
                     0px 0px 1px rgba(0, 0, 0, 0.25); */
  --light-purple-depressed: inset 1px 1px 6px #dadbdf, inset -1px -1px 6px #dadbdf,
    inset 1px 1px 6px rgba(132, 110, 255, 0.3), inset -1px -1px 5px rgba(255, 255, 255, 0.75);
  --elevated-button: 0px 1px 3px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.15), 0px 2px 1px rgba(0, 0, 0, 0.12);

  /* UI height, corners, and sizes */
  --border-radius-sm: 4px;
  --border-radius-md: 8px;
  --border-radius-lg: 12px;

  --spacing-base: 8px;
  --spacing-xs: calc(var(--spacing-base) * 0.5);
  --spacing-sm: calc(var(--spacing-base) * 1);
  --spacing-md: calc(var(--spacing-base) * 1.5);
  --spacing-lg: calc(var(--spacing-base) * 2.5);
  --spacing-xl: calc(var(--spacing-base) * 4);
  --spacing-xxl: calc(var(--spacing-base) * 6.5);
  --spacing-xxxl: calc(var(--spacing-base) * 10.5);
  --spacing-xxxxl: calc(var(--spacing-base) * 17);

  --page-side-padding: calc(var(--spacing-xl) + var(--spacing-base));

  --mobile-width: calc(100vw - 10px);

  /* z-index */
  --z-index-bg: 0;
  --z-index-footer: 50;
  --z-index-dropdown: 100;
  --z-index-input: 500;
  --z-index-loader: 750;
  --z-index-toast: 900;
  --z-index-navbar: 1000;
  --z-index-navbar-dropdown: 1500;
  --z-index-modal: 5000;

  /* Transitions */
  --bounce: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  --ease-in-out: cubic-bezier(0.645, 0.045, 0.355, 1);
  --ease-in: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  --ease-out: cubic-bezier(0.215, 0.61, 0.355, 1);
  --ease-out-back: cubic-bezier(0.34, 1.56, 0.64, 1);

  /* juiced-up ease-in */
  /* transition-timing-function: */
  --juiced-up-ease-in: cubic-bezier(0.75, 0, 1, 1);
  /* juiced-up ease */
  /* transition-timing-function: */
  --juiced-up-ease: cubic-bezier(0.44, 0.21, 0, 1);
  --quick-spin: cubic-bezier(0.07, 0.98, 0.3, 1.88);
}

html,
#root,
:root {
  /* avatars */
  --avatar-size-xs: 32px;
  --avatar-size-sm: 48px;
  --avatar-size-md: 72px;
  --avatar-size-lg: 96px;
  --avatar-size-xl: 160px;

  /* badges */
  --badge-width: 96px;
  --badge-height: 28px;
  --badge-font-size: 15px;
  --badge-border-radius: var(--border-radius-sm);
  --badge-padding-left: 8px;
  --badge-padding-right: 8px;
  --badge-padding-top: 4px;
  --badge-padding-bot: 4px;
  --badge-padding: var(--badge-padding-top) var(--badge-padding-right) var(--badge-padding-bot)
    var(--badge-padding-left);
  --badge-color-primary: var(--color-primary);
  --badge-color-secondary: var(--color-secondary);
  --badge-color-accent: var(--color-accent);
  --badge-font-color-primary: var(--pure-white);
  --badge-font-color-secondary: var(--font-color-dark);
  --badge-font-color-accent: var(--pure-white);

  /* buttons */
  --button-xs-font-size: 14px;
  --button-sm-font-size: 16px;
  --button-md-font-size: 16px;
  --button-lg-font-size: 18px;
  --button-xl-font-size: 20px;

  --button-border-radius: var(--border-radius-sm);

  --button-primary-background-default: var(--color-primary);
  --button-primary-background-hover: var(--color-primary-dark);
  --button-primary-background-inactive: var(--inactive-background-color);
  --button-primary-background-destructive: var(--red-destructive);

  --button-primary-font-color-default: var(--pure-white);
  --button-primary-font-color-hover: var(--pure-white);
  --button-primary-font-color-inactive: var(--font-color-subdued);
  --button-primary-font-color-destructive: var(--pure-white);

  --button-primary-border-color-default: var(--color-primary);
  --button-primary-border-color-hover: var(--color-primary-dark);
  --button-primary-border-color-inactive: var(--font-color-subdued);
  --button-primary-border-color-destructive: var(--red-destructive);

  --button-secondary-background-default: var(--pure-white);
  --button-secondary-background-hover: var(--purple-blue-extra-light);
  --button-secondary-background-inactive: var(--pure-white);
  --button-secondary-background-destructive: var(--pure-white);

  --button-secondary-font-color-default: var(--color-primary);
  --button-secondary-font-color-hover: var(--color-primary);
  --button-secondary-font-color-inactive: var(--inactive-color);
  --button-secondary-font-color-destructive: var(--red-destructive);

  --button-secondary-border-color-default: var(--color-primary);
  --button-secondary-border-color-hover: var(--color-primary);
  --button-secondary-border-color-inactive: var(--inactive-color);
  --button-secondary-border-color-destructive: var(--red-destructive);

  --button-plain-background-default: transparent;
  --button-plain-background-hover: var(--color-primary-lightest);
  --button-plain-background-inactive: transparent;
  --button-plain-background-destructive: transparent;

  --button-plain-font-color-default: var(--color-primary);
  --button-plain-font-color-hover: var(--color-primary-dark);
  --button-plain-font-color-inactive: var(--font-color-subdued);
  --button-plain-font-color-destructive: var(--red-destructive);

  --button-plain-border-color-default: transparent;
  --button-plain-border-color-hover: transparent;
  --button-plain-border-color-inactive: transparent;
  --button-plain-border-color-destructive: transparent;

  --button-base-padding-left: 16px;
  --button-base-padding-right: 16px;
  --button-base-padding-top: 8px;
  --button-base-padding-bot: 8px;
  --button-base-padding: var(--button-base-padding-top) var(--button-base-padding-right) var(--button-base-padding-bot)
    var(--button-base-padding-left);
  --button-xs-padding-left: calc(var(--button-base-padding-left) * 0.75);
  --button-xs-padding-right: calc(var(--button-base-padding-right) * 0.75);
  --button-xs-padding-top: calc(var(--button-base-padding-top) * 0.75);
  --button-xs-padding-bot: calc(var(--button-base-padding-bot) * 0.75);
  --button-xs-padding: var(--button-xs-padding-top) var(--button-xs-padding-right) var(--button-xs-padding-bot)
    var(--button-xs-padding-left);
  --button-sm-padding-left: calc(var(--button-base-padding-left) * 0.75);
  --button-sm-padding-right: calc(var(--button-base-padding-right) * 0.75);
  --button-sm-padding-top: calc(var(--button-base-padding-top) * 0.75);
  --button-sm-padding-bot: calc(var(--button-base-padding-bot) * 0.75);
  --button-sm-padding: var(--button-sm-padding-top) var(--button-sm-padding-right) var(--button-sm-padding-bot)
    var(--button-sm-padding-left);
  --button-md-padding-left: calc(var(--button-base-padding-left) * 1.2);
  --button-md-padding-right: calc(var(--button-base-padding-right) * 1.2);
  --button-md-padding-top: calc(var(--button-base-padding-top) * 1.2);
  --button-md-padding-bot: calc(var(--button-base-padding-bot) * 1.2);
  --button-md-padding: var(--button-md-padding-top) var(--button-md-padding-right) var(--button-md-padding-bot)
    var(--button-md-padding-left);
  --button-lg-padding-left: calc(var(--button-base-padding-left) * 1.25);
  --button-lg-padding-right: calc(var(--button-base-padding-right) * 1.25);
  --button-lg-padding-top: calc(var(--button-base-padding-top) * 1.25);
  --button-lg-padding-bot: calc(var(--button-base-padding-bot) * 1.25);
  --button-lg-padding: var(--button-lg-padding-top) var(--button-lg-padding-right) var(--button-lg-padding-bot)
    var(--button-lg-padding-left);
  --button-xl-padding-left: calc(var(--button-base-padding-left) * 1.3);
  --button-xl-padding-right: calc(var(--button-base-padding-right) * 1.3);
  --button-xl-padding-top: calc(var(--button-base-padding-top) * 1.3);
  --button-xl-padding-bot: calc(var(--button-base-padding-bot) * 1.3);
  --button-xl-padding: var(--button-xl-padding-top) var(--button-xl-padding-right) var(--button-xl-padding-bot)
    var(--button-xl-padding-left);

  /* card */
  --card-background-color: var(--pure-white);
  --card-border-radius: var(--border-radius-sm);
  --card-border-color: var(--background-color-very-light-blue);
  --card-elevation-flat: var(--elevation-0);
  --card-elevation-low: var(--elevation-1);
  --card-elevation-med: var(--elevation-2);
  --card-elevation-high: var(--elevation-3);
  --card-elevation-very-high: var(--elevation-4);
  --card-elevation-highest: var(--elevation-5);
  --card-padding-top: var(--spacing-base);
  --card-padding-bot: var(--spacing-base);
  --card-padding-left: var(--spacing-md);
  --card-padding-right: var(--spacing-md);
  --card-padding: var(--card-padding-top) var(--card-padding-right) var(--card-padding-bot) var(--card-padding-left);

  /* checkboxes */
  --checkbox-border-radius: calc(var(--border-radius-sm) * 0.5);
  /* --checkbox-width: 16px;
  --checkbox-height: 16px; */
  --checkbox-width: 16px;
  --checkbox-height: 16px;
  --checkbox-inner-border-radius: calc(var(--border-radius-sm) * 0.25);
  --checkbox-inner-width: 8px;
  --checkbox-inner-height: 8px;

  --checkbox-label-font-size: 16px;
  --checkbox-label-font-color: var(--font-color-subdued-header);
  --checkbox-label-font-color-selected: var(--color-primary);

  --checkbox-inactive-color: var(--inactive-color);
  --checkbox-selected-border-color: var(--color-primary);
  --checkbox-unselected-border-color: var(--font-color-subdued-header);
  --checkbox-transition-box-shadow-color: var(--color-primary-a15);

  /* chips */
  --chip-border-radius: var(--border-radius-sm);
  /* --chip-padding-left: 12px;
  --chip-padding-right: 12px;
  --chip-padding-top: 16px;
  --chip-padding-bot: 16px; */
  /* --chip-padding-left: calc(var(--spacing-base) * 1.5);
  --chip-padding-right: calc(var(--spacing-base) * 1.5);
  --chip-padding-top: calc(var(--spacing-base) * 2);
  --chip-padding-bot: calc(var(--spacing-base) * 2); */
  /* --chip-padding-base: var(--chip-padding-top)
                       var(--chip-padding-right)
                       var(--chip-padding-bot)
                       var(--chip-padding-left); */
  --chip-padding-top-bot-xs: calc(var(--spacing-base) * 1);
  --chip-padding-left-right-xs: calc(var(--spacing-base) * 1.5);
  --chip-padding-top-bot-sm: calc(var(--spacing-base) * 1.25);
  --chip-padding-left-right-sm: calc(var(--spacing-base) * 1.75);
  --chip-padding-top-bot-md: calc(var(--spacing-base) * 1.5);
  --chip-padding-left-right-md: calc(var(--spacing-base) * 2);
  --chip-padding-top-bot-lg: calc(var(--spacing-base) * 1.75);
  --chip-padding-left-right-lg: calc(var(--spacing-base) * 2.25);
  --chip-padding-top-bot-xl: calc(var(--spacing-base) * 2);
  --chip-padding-left-right-xl: calc(var(--spacing-base) * 2.5);

  --chip-padding-xs: var(--chip-padding-top-bot-xs) var(--chip-padding-left-right-xs);
  --chip-padding-sm: var(--chip-padding-top-bot-sm) var(--chip-padding-left-right-sm);
  --chip-padding-md: var(--chip-padding-top-bot-md) var(--chip-padding-left-right-md);
  --chip-padding-lg: var(--chip-padding-top-bot-lg) var(--chip-padding-left-right-lg);
  --chip-padding-xl: var(--chip-padding-top-bot-xl) var(--chip-padding-left-right-xl);

  --chip-font-size-xs: 12px;
  --chip-font-size-sm: 14px;
  --chip-font-size-md: 16px;
  --chip-font-size-lg: 18px;
  --chip-font-size-xl: 20px;

  --chip-border-radius: var(--border-radius-md);
  --chip-font-color: var(--font-color-primary);

  --chip-background-color-primary: var(--color-primary-a10);
  --chip-background-color-secondary: var(--turqoise-a60);
  /* --chip-background-color-accent: var(--red-extra-light); */
  --chip-background-color-accent: var(--color-accent);

  /* dropdowns */
  --dropdown-menu-elevation: var(--card-elevation-high);
  --dropdown-standard-width: 300px;
  --dropdown-border-radius: var(--border-radius-md);

  --dropdown-background-color: var(--pure-white);
  /* --dropdown-background-color-hover: var(--color-primary-lightest); */
  --dropdown-background-color-hover: var(--color-primary-a10);
  --dropdown-background-color-selected: var(--color-primary-lightest);
  --dropdown-background-color-hover-error: var(--red-extra-light);

  --dropdown-font-color-focused: var(--font-color-primary);
  --dropdown-font-color-default: var(--font-color-primary);
  --dropdown-font-color-selected: var(--color-primary);
  --dropdown-font-color-hover: var(--color-primary);
  --dropdown-font-color-error: var(--red-destructive);
  --dropdown-font-color-inactive: var(--inactive-color);

  --dropdown-border-color-default: var(--border-color);
  --dropdown-border-color-focused: var(--color-primary);
  --dropdown-border-color-error: var(--red-destructive);
  --dropdown-border-color-inactive: var(--inactive-color);

  --dropdown-helper-text-font-size: 12px;
  /* --dropdown-helper-text-font-size: var(--input-help); */
  --dropdown-helper-text-color-default: var(--border-color);
  --dropdown-helper-text-color-focused: var(--font-color-subdued);
  --dropdown-helper-text-color-selected: var(--color-primary);
  --dropdown-helper-text-color-error: var(--red-destructive);
  --dropdown-helper-text-color-inactive: var(--inactive-color);

  /* dropzone/file upload inputs */
  --dropzone-button-border: var(--grey-linear-gradient);

  --dropzone-background-color: var(--pure-white);
  --dropzone-background-color-hover: var(--color-primary-lightest);
  --dropzone-background-color-active: var(--color-primary-lightest);

  --dropzone-upload-icon-color: var(--icon-color-default);
  --dropzone-upload-icon-color-hover: var(--font-color-less-dark);
  --dropzone-upload-icon-color-active: var(--font-color-less-dark);

  --dropzone-font-color: var(--font-color-subdued-header);
  --dropzone-font-color-active: var(--color-primary);

  --dropzone-border-color-default: var(--border-color);
  --dropzone-border-color-accepted: var(--border-color);
  --dropzone-border-color-focused: var(--color-primary);
  --dropzone-border-color-active: var(--color-primary);
  --dropzone-border-color-error: var(--red-destructive);

  /* footer */
  --footer-z-index: var(--z-index-footer);
  --footer-background: var(--pure-white);
  --footer-separator-color: var(--background-color-very-light-blue);
  --footer-column-header-text-color: var(--font-color-subdued-header);
  --footer-column-text-color: var(--font-color-subdued);
  --footer-column-header-font-size: 15px;
  --footer-column-text-font-size: 14px;

  /* inputs */
  --input-border-radius: var(--border-radius-sm);
  --input-base-height: 48px;
  --input-base-width: 300px;
  --input-padding-left: var(--spacing-md); /* 12px */
  --input-padding-right: var(--spacing-md); /* 12px */
  --input-padding-top: var(--spacing-base); /* 8px */
  --input-padding-bot: var(--spacing-base); /* 8px */
  --input-padding: var(--input-padding-top) var(--input-padding-right) var(--input-padding-bot)
    var(--input-padding-left);

  --input-label-text-size: 12px;
  --input-label-text-color: var(--font-color-subdued-header);
  --input-label-text-margin-bot: var(--spacing-xs);

  --input-helper-text-size: 12px;
  --input-helper-text-margin-top: var(--spacing-xs);
  --input-helper-text-color-default: var(--font-color-subdued);
  --input-helper-text-color-focused: var(--font-color-subdued-header);
  --input-helper-text-color-error: var(--red-destructive);
  --input-helper-text-color-success: var(--success-300);

  --input-font-size: 14px;
  --input-font-placeholder-color: var(--font-color-subdued);
  --input-font-color: var(--font-color-less-dark);
  --input-background-color-default: var(--pure-white);
  --input-background-color-hover: var(--color-primary-lightest);
  --input-border-color-default: var(--border-color);
  --input-border-color-focused: var(--color-primary);
  --input-border-color-error: var(--red-destructive);
  --input-border-color-success: var(--success-300);

  /* keypad */
  --keypad-height-sm: 48px;
  --keypad-height-md: 72px;
  --keypad-height-sm: 96px;
  --keypad-height-sm: 160px;
  --keypad-width-sm: 48px;
  --keypad-width-md: 72px;
  --keypad-width-lg: 96px;
  --keypad-width-xl: 160px;
  --keypad-width-full-width: 100%;

  /* modals */
  --modal-background: var(--pure-white);
  --modal-border-radius: var(--border-radius-lg);
  --modal-elevation: var(--elevation-4);
  --modal-padding-left: var(--spacing-xl);
  --modal-padding-right: var(--spacing-xl);
  --modal-padding-top: var(--spacing-lg);
  --modal-padding-bot: var(--spacing-lg);
  --modal-padding: var(--modal-padding-top) var(--modal-padding-right) var(--modal-padding-bot)
    var(--modal-padding-left);
  --modal-header-font-size: 24px;
  --modal-header-font-color: var(--font-color-subdued-header);
  --modal-content-font-size: 18px;
  --modal-content-font-color: var(--font-color-subdued);
  --modal-card-max-width: 800px;

  /* multi-select / combobox */
  /* get to this later */

  /* navbar */
  --navbar-height: 90px;
  --navbar-padding-left: var(--page-side-padding);
  --navbar-padding-right: var(--page-side-padding);
  --navbar-background-color: var(--pure-white);

  /* notification dot */
  --notification-dot-color-accent: var(--color-accent);
  --notification-dot-color-primary: var(--color-primary);
  --notification-dot-color-success: var(--color-secondary);
  --notification-dot-color-warning: var(--warning-300);
  --notification-dot-border-color: var(--pure-white);
  --notification-dot-border-size: 5px;

  /* radio buttons */
  --radio-button-width: 16px;
  --radio-button-height: 16px;
  --radio-button-inner-width: 8px;
  --radio-button-inner-height: 8px;

  --radio-button-label-font-size: 16px;
  --radio-button-label-font-color: var(--font-color-subdued-header);
  --radio-button-label-font-color-selected: var(--color-primary);

  --radio-button-inactive-color: var(--inactive-color);
  --radio-button-selected-border-color: var(--color-primary);
  --radio-button-unselected-border-color: var(--font-color-subdued-header);
  --radio-button-transition-box-shadow-color: var(--color-primary-a15);

  /* sliders */
  /* deal with this later */

  /* spinner */
  --spinner-color-background: var(--color-primary-light);
  --spinner-color-primary: var(--color-primary);
  --spinner-color-secondary: var(--color-secondary);
  --spinner-color-accent: var(--pink1); /* can't do linear gradient border color */

  --spinner-section-z-index: var(--z-index-loader);
  --spinner-section-size-xs: 5px;
  --spinner-section-size-sm: 5px;
  --spinner-section-size-md: 10px;
  --spinner-section-size-lg: 20px;
  --spinner-section-size-full-size: 20px;

  --spinner-container-width-xs: 25px;
  --spinner-container-height-xs: 25px;
  --spinner-container-width-sm: 50px;
  --spinner-container-height-sm: 50px;
  --spinner-container-width-md: 100px;
  --spinner-container-height-md: 100px;
  --spinner-container-width-lg: 250px;
  --spinner-container-height-lg: 250px;
  --spinner-container-width-full-size: 100%;
  --spinner-container-height-full-size: 100%;

  /* stars */
  --star-size: 32px;
  --star-color-filled: var(--star-gold);
  --star-color-empty: var(--background-color-very-light-blue);

  /* tabs */
  /* do this later */

  /* tags */
  /* do this later */

  /* text area */
  --text-area-border-radius: var(--border-radius-sm);
  --text-area-padding-left: var(--spacing-md); /* 12px */
  --text-area-padding-right: var(--spacing-md); /* 12px */
  --text-area-padding-top: var(--spacing-base); /* 8px */
  --text-area-padding-bot: var(--spacing-base); /* 8px */
  --text-area-padding: var(--text-area-padding-top) var(--text-area-padding-right) var(--text-area-padding-bot)
    var(--text-area-padding-left);

  --text-area-label-text-size: 12px;
  --text-area-label-text-color: var(--font-color-subdued-header);
  --text-area-label-text-margin-bot: var(--spacing-xs);

  --text-area-helper-text-size: 12px;
  --text-area-helper-text-margin-top: var(--spacing-xs);
  --text-area-helper-text-color-default: var(--font-color-subdued);
  --text-area-helper-text-color-focused: var(--font-color-subdued-header);
  --text-area-helper-text-color-error: var(--red-destructive);
  --text-area-helper-text-color-success: var(--success-300);

  --text-area-font-size: 14px;
  --text-area-font-placeholder-color: var(--font-color-subdued);
  --text-area-font-color: var(--font-color-less-dark);
  --text-area-background-color: var(--pure-white);
  --text-area-background-color-hover: var(--color-primary-lightest);
  --text-area-border-color-default: var(--border-color);
  --text-area-border-color-focused: var(--color-primary);
  --text-area-border-color-error: var(--red-destructive);
  --text-area-border-color-success: var(--success-300);

  /* toasts */
  --toast-width: 400px;
  --toast-border-radius: var(--border-radius-sm);
  --toast-colored-border-size: var(--border-radius-sm);
  --toast-color-info: var(--color-primary);
  --toast-color-danger: var(--red-destructive);
  --toast-color-warning: var(--warning-300);
  --toast-color-success: var(--success-400);

  --toast-color-info-light: var(--color-primary-a10);
  --toast-color-danger-light: var(--red-extra-light);
  --toast-color-warning-light: var(--warning-100);
  --toast-color-success-light: var(--success-100);

  --toast-button-text-color-info: var(--color-primary);
  --toast-button-text-color-danger: var(--red-destructive);
  --toast-button-text-color-warning: var(--font-color-primary);
  --toast-button-text-color-success: var(--success-500);

  --toast-title-color: var(--font-color-subdued-header);
  --toast-body-color: var(--font-color-subdued);

  /* toggle */
  --toggle-border-radius: calc(var(--border-radius-lg) * 2);
  --toggle-box-shadow: var(--light-purple-depressed);
  --toggle-border-color: var(--border-color);
  --toggle-button-margin: 4px;
  --toggle-button-border-radius: 50%;
  --toggle-button-box-shadow: var(--elevated-button);
  --toggle-button-background-color: var(--color-primary-lightest);
  --toggle-button-background-color-on: var(--color-secondary);
  --toggle-button-label-font-size: 16px;
  --toggle-button-label-font-color: var(--font-color-subdued);

  /* wizard forms */
  /* do this later */
}

html,
:root {
  /* MATERIAL DESIGN COLORS */

  /* BLUE */
  --blue-50: #e3f2fd;
  --blue-100: #bbdefb;
  --blue-200: #90caf9;
  --blue-300: #64b5f6;
  --blue-400: #42a5f5;
  --blue-500: #2196f3;
  --blue-600: #1e88e5;
  --blue-700: #1976d2;
  --blue-800: #1565c0;
  --blue-900: #0d47a1;
  --blue-a100: #82b1ff;
  --blue-a200: #448aff;
  --blue-a400: #2979ff;
  --blue-a700: #2962ff;

  /* RED */
  --red-50: #ffebee;
  --red-100: #ffcdd2;
  --red-200: #ef9a9a;
  --red-300: #e57373;
  --red-400: #ef5350;
  --red-500: #f44336;
  --red-600: #e53935;
  --red-700: #d32f2f;
  --red-800: #c62828;
  --red-900: #b71c1c;

  /*PINK*/
  --pink-50: #fce4ec;
  --pink-100: #f8bbd0;
  --pink-200: #f48fb1;
  --pink-300: #f06292;
  --pink-400: #ec407a;
  --pink-500: #e91e63;
  --pink-600: #d81b60;
  --pink-700: #c2185b;
  --pink-800: #ad1457;
  --pink-900: #880e4f;
  --pink-a100: #ff80ab;
  --pink-a200: #ff4081;
  --pink-a400: #f50057;
  --pink-a700: #c51162;

  /* PURPLE */
  --purple-50: #f3e5f5;
  --purple-100: #e1bee7;
  --purple-200: #ce93d8;
  --purple-300: #ba68c8;
  --purple-400: #ab47bc;
  --purple-500: #9c27b0;
  --purple-600: #8e24aa;
  --purple-700: #7b1fa2;
  --purple-800: #6a1b9a;
  --purple-900: #4a148c;
  --purple-a100: #ea80fc;
  --purple-a200: #e040fb;
  --purple-a400: #d500f9;
  --purple-a700: #aa00ff;

  /* DEEPPURPLE */
  --deeppurple-50: #ede7f6;
  --deeppurple-100: #d1c4e9;
  --deeppurple-200: #b39ddb;
  --deeppurple-300: #9575cd;
  --deeppurple-400: #7e57c2;
  --deeppurple-500: #673ab7;
  --deeppurple-600: #5e35b1;
  --deeppurple-700: #512da8;
  --deeppurple-800: #4527a0;
  --deeppurple-900: #311b92;
  --deeppurple-a100: #b388ff;
  --deeppurple-a200: #7c4dff;
  --deeppurple-a400: #651fff;
  --deeppurple-a700: #6200ea;

  /* INDIGO */
  --indigo-50: #e8eaf6;
  --indigo-100: #c5cae9;
  --indigo-200: #9fa8da;
  --indigo-300: #7986cb;
  --indigo-400: #5c6bc0;
  --indigo-500: #3f51b5;
  --indigo-600: #3949ab;
  --indigo-700: #303f9f;
  --indigo-800: #283593;
  --indigo-900: #1a237e;
  --indigo-a100: #8c9eff;
  --indigo-a200: #536dfe;
  --indigo-a400: #3d5afe;
  --indigo-a700: #304ffe;

  /* LIGHTBLUE */
  --lightblue-50: #e1f5fe;
  --lightblue-100: #b3e5fc;
  --lightblue-200: #81d4fa;
  --lightblue-300: #4fc3f7;
  --lightblue-400: #29b6f6;
  --lightblue-500: #03a9f4;
  --lightblue-600: #039be5;
  --lightblue-700: #0288d1;
  --lightblue-800: #0277bd;
  --lightblue-900: #01579b;
  --lightblue-a100: #80d8ff;
  --lightblue-a200: #40c4ff;
  --lightblue-a400: #00b0ff;
  --lightblue-a700: #0091ea;

  /* CYAN */
  --cyan-50: #e0f7fa;
  --cyan-100: #b2ebf2;
  --cyan-200: #80deea;
  --cyan-300: #4dd0e1;
  --cyan-400: #26c6da;
  --cyan-500: #00bcd4;
  --cyan-600: #00acc1;
  --cyan-700: #0097a7;
  --cyan-800: #00838f;
  --cyan-900: #006064;
  --cyan-a100: #84ffff;
  --cyan-a200: #18ffff;
  --cyan-a400: #00e5ff;
  --cyan-a700: #00b8d4;

  /* TEAL */
  --teal-50: #e0f2f1;
  --teal-100: #b2dfdb;
  --teal-200: #80cbc4;
  --teal-300: #4db6ac;
  --teal-400: #26a69a;
  --teal-500: #009688;
  --teal-600: #00897b;
  --teal-700: #00796b;
  --teal-800: #00695c;
  --teal-900: #004d40;
  --teal-a100: #a7ffeb;
  --teal-a200: #64ffda;
  --teal-a400: #1de9b6;
  --teal-a700: #00bfa5;

  /* GREEN */
  --green-50: #e8f5e9;
  --green-100: #c8e6c9;
  --green-200: #a5d6a7;
  --green-300: #81c784;
  --green-400: #66bb6a;
  --green-500: #4caf50;
  --green-600: #4d794f;
  --green-700: #388e3c;
  --green-800: #2e7d32;
  --green-900: #1b5e20;
  --green-a100: #b9f6ca;
  --green-a200: #69f0ae;
  --green-a400: #00e676;
  --green-a700: #00c853;

  /* LIGHTGREEN */
  --lightgreen-50: #f1f8e9;
  --lightgreen-100: #dcedc8;
  --lightgreen-200: #c5e1a5;
  --lightgreen-300: #aed581;
  --lightgreen-400: #9ccc65;
  --lightgreen-500: #8bc34a;
  --lightgreen-600: #7cb342;
  --lightgreen-700: #689f38;
  --lightgreen-800: #558b2f;
  --lightgreen-900: #33691e;
  --lightgreen-a100: #ccff90;
  --lightgreen-a200: #b2ff59;
  --lightgreen-a400: #76ff03;
  --lightgreen-a700: #64dd17;

  /* LIME */
  --lime-50: #f9fbe7;
  --lime-100: #f0f4c3;
  --lime-200: #e6ee9c;
  --lime-300: #dce775;
  --lime-400: #d4e157;
  --lime-500: #cddc39;
  --lime-600: #c0ca33;
  --lime-700: #afb42b;
  --lime-800: #9e9d24;
  --lime-900: #827717;
  --lime-a100: #f4ff81;
  --lime-a200: #eeff41;
  --lime-a400: #c6ff00;
  --lime-a700: #aeea00;

  /* YELLOW */
  --yellow-50: #fffde7;
  --yellow-100: #fff9c4;
  --yellow-200: #fff59d;
  --yellow-300: #fff176;
  --yellow-400: #ffee58;
  --yellow-500: #ffeb3b;
  --yellow-600: #fdd835;
  --yellow-700: #fbc02d;
  --yellow-800: #f9a825;
  --yellow-900: #f57f17;
  --yellow-a100: #ffff8d;
  --yellow-a200: #ffff00;
  --yellow-a400: #ffea00;
  --yellow-a700: #ffd600;

  /* AMBER */
  --amber-50: #fff8e1;
  --amber-100: #ffecb3;
  --amber-200: #ffe082;
  --amber-300: #ffd54f;
  --amber-400: #ffca28;
  --amber-500: #ffc107;
  --amber-600: #ffb300;
  --amber-700: #ffa000;
  --amber-800: #ff8f00;
  --amber-900: #ff6f00;
  --amber-a100: #ffe57f;
  --amber-a200: #ffd740;
  --amber-a400: #ffc400;
  --amber-a700: #ffab00;

  /* ORANGE */
  --orange-50: #fff3e0;
  --orange-100: #ffe0b2;
  --orange-200: #ffcc80;
  --orange-300: #ffb74d;
  --orange-400: #ffa726;
  --orange-500: #ff9800;
  --orange-600: #fb8c00;
  --orange-700: #f57c00;
  --orange-800: #ef6c00;
  --orange-900: #e65100;
  --orange-a100: #ffd180;
  --orange-a200: #ffab40;
  --orange-a400: #ff9100;
  --orange-a700: #ff6d00;

  /* DEEPORANGE */
  --deeporange-50: #fbe9e7;
  --deeporange-100: #ffccbc;
  --deeporange-200: #ffab91;
  --deeporange-300: #ff8a65;
  --deeporange-400: #ff7043;
  --deeporange-500: #ff5722;
  --deeporange-600: #f4511e;
  --deeporange-700: #e64a19;
  --deeporange-800: #d84315;
  --deeporange-900: #bf360c;
  --deeporange-a100: #ff9e80;
  --deeporange-a200: #ff6e40;
  --deeporange-a400: #ff3d00;
  --deeporange-a700: #dd2c00;

  /* BROWN */
  --brown-50: #efebe9;
  --brown-100: #d7ccc8;
  --brown-200: #bcaaa4;
  --brown-300: #a1887f;
  --brown-400: #8d6e63;
  --brown-500: #795548;
  --brown-600: #6d4c41;
  --brown-700: #5d4037;
  --brown-800: #4e342e;
  --brown-900: #3e2723;

  /* GREY */
  --grey-50: #fafafa;
  --grey-100: #f5f5f5;
  --grey-200: #eeeeee;
  --grey-300: #e0e0e0;
  --grey-400: #bdbdbd;
  --grey-500: #9e9e9e;
  --grey-600: #757575;
  --grey-700: #616161;
  --grey-800: #424242;
  --grey-900: #212121;

  /* BLUEGREY */
  --bluegrey-50: #eceff1;
  --bluegrey-100: #cfd8dc;
  --bluegrey-200: #b0bec5;
  --bluegrey-300: #90a4ae;
  --bluegrey-400: #78909c;
  --bluegrey-500: #607d8b;
  --bluegrey-600: #546e7a;
  --bluegrey-700: #455a64;
  --bluegrey-800: #37474f;
  --bluegrey-900: #263238;
}

html,
:root {
  /* MATERIAL DESIGN COLORS */

  /* BLUE */
  --blue-50: #e3f2fd;
  --blue-100: #bbdefb;
  --blue-200: #90caf9;
  --blue-300: #64b5f6;
  --blue-400: #42a5f5;
  --blue-500: #2196f3;
  --blue-600: #1e88e5;
  --blue-700: #1976d2;
  --blue-800: #1565c0;
  --blue-900: #0d47a1;
  --blue-a100: #82b1ff;
  --blue-a200: #448aff;
  --blue-a400: #2979ff;
  --blue-a700: #2962ff;

  /* RED */
  --red-50: #ffebee;
  --red-100: #ffcdd2;
  --red-200: #ef9a9a;
  --red-300: #e57373;
  --red-400: #ef5350;
  --red-500: #f44336;
  --red-600: #e53935;
  --red-700: #d32f2f;
  --red-800: #c62828;
  --red-900: #b71c1c;

  /*PINK*/
  --pink-50: #fce4ec;
  --pink-100: #f8bbd0;
  --pink-200: #f48fb1;
  --pink-300: #f06292;
  --pink-400: #ec407a;
  --pink-500: #e91e63;
  --pink-600: #d81b60;
  --pink-700: #c2185b;
  --pink-800: #ad1457;
  --pink-900: #880e4f;
  --pink-a100: #ff80ab;
  --pink-a200: #ff4081;
  --pink-a400: #f50057;
  --pink-a700: #c51162;

  /* PURPLE */
  --purple-50: #f3e5f5;
  --purple-100: #e1bee7;
  --purple-200: #ce93d8;
  --purple-300: #ba68c8;
  --purple-400: #ab47bc;
  --purple-500: #9c27b0;
  --purple-600: #8e24aa;
  --purple-700: #7b1fa2;
  --purple-800: #6a1b9a;
  --purple-900: #4a148c;
  --purple-a100: #ea80fc;
  --purple-a200: #e040fb;
  --purple-a400: #d500f9;
  --purple-a700: #aa00ff;

  /* DEEPPURPLE */
  --deeppurple-50: #ede7f6;
  --deeppurple-100: #d1c4e9;
  --deeppurple-200: #b39ddb;
  --deeppurple-300: #9575cd;
  --deeppurple-400: #7e57c2;
  --deeppurple-500: #673ab7;
  --deeppurple-600: #5e35b1;
  --deeppurple-700: #512da8;
  --deeppurple-800: #4527a0;
  --deeppurple-900: #311b92;
  --deeppurple-a100: #b388ff;
  --deeppurple-a200: #7c4dff;
  --deeppurple-a400: #651fff;
  --deeppurple-a700: #6200ea;

  /* INDIGO */
  --indigo-50: #e8eaf6;
  --indigo-100: #c5cae9;
  --indigo-200: #9fa8da;
  --indigo-300: #7986cb;
  --indigo-400: #5c6bc0;
  --indigo-500: #3f51b5;
  --indigo-600: #3949ab;
  --indigo-700: #303f9f;
  --indigo-800: #283593;
  --indigo-900: #1a237e;
  --indigo-a100: #8c9eff;
  --indigo-a200: #536dfe;
  --indigo-a400: #3d5afe;
  --indigo-a700: #304ffe;

  /* LIGHTBLUE */
  --lightblue-50: #e1f5fe;
  --lightblue-100: #b3e5fc;
  --lightblue-200: #81d4fa;
  --lightblue-300: #4fc3f7;
  --lightblue-400: #29b6f6;
  --lightblue-500: #03a9f4;
  --lightblue-600: #039be5;
  --lightblue-700: #0288d1;
  --lightblue-800: #0277bd;
  --lightblue-900: #01579b;
  --lightblue-a100: #80d8ff;
  --lightblue-a200: #40c4ff;
  --lightblue-a400: #00b0ff;
  --lightblue-a700: #0091ea;

  /* CYAN */
  --cyan-50: #e0f7fa;
  --cyan-100: #b2ebf2;
  --cyan-200: #80deea;
  --cyan-300: #4dd0e1;
  --cyan-400: #26c6da;
  --cyan-500: #00bcd4;
  --cyan-600: #00acc1;
  --cyan-700: #0097a7;
  --cyan-800: #00838f;
  --cyan-900: #006064;
  --cyan-a100: #84ffff;
  --cyan-a200: #18ffff;
  --cyan-a400: #00e5ff;
  --cyan-a700: #00b8d4;

  /* TEAL */
  --teal-50: #e0f2f1;
  --teal-100: #b2dfdb;
  --teal-200: #80cbc4;
  --teal-300: #4db6ac;
  --teal-400: #26a69a;
  --teal-500: #009688;
  --teal-600: #00897b;
  --teal-700: #00796b;
  --teal-800: #00695c;
  --teal-900: #004d40;
  --teal-a100: #a7ffeb;
  --teal-a200: #64ffda;
  --teal-a400: #1de9b6;
  --teal-a700: #00bfa5;

  /* GREEN */
  --green-50: #e8f5e9;
  --green-100: #c8e6c9;
  --green-200: #a5d6a7;
  --green-300: #81c784;
  --green-400: #66bb6a;
  --green-500: #4caf50;
  --green-600: #4d794f;
  --green-700: #388e3c;
  --green-800: #2e7d32;
  --green-900: #1b5e20;
  --green-a100: #b9f6ca;
  --green-a200: #69f0ae;
  --green-a400: #00e676;
  --green-a700: #00c853;

  /* LIGHTGREEN */
  --lightgreen-50: #f1f8e9;
  --lightgreen-100: #dcedc8;
  --lightgreen-200: #c5e1a5;
  --lightgreen-300: #aed581;
  --lightgreen-400: #9ccc65;
  --lightgreen-500: #8bc34a;
  --lightgreen-600: #7cb342;
  --lightgreen-700: #689f38;
  --lightgreen-800: #558b2f;
  --lightgreen-900: #33691e;
  --lightgreen-a100: #ccff90;
  --lightgreen-a200: #b2ff59;
  --lightgreen-a400: #76ff03;
  --lightgreen-a700: #64dd17;

  /* LIME */
  --lime-50: #f9fbe7;
  --lime-100: #f0f4c3;
  --lime-200: #e6ee9c;
  --lime-300: #dce775;
  --lime-400: #d4e157;
  --lime-500: #cddc39;
  --lime-600: #c0ca33;
  --lime-700: #afb42b;
  --lime-800: #9e9d24;
  --lime-900: #827717;
  --lime-a100: #f4ff81;
  --lime-a200: #eeff41;
  --lime-a400: #c6ff00;
  --lime-a700: #aeea00;

  /* YELLOW */
  --yellow-50: #fffde7;
  --yellow-100: #fff9c4;
  --yellow-200: #fff59d;
  --yellow-300: #fff176;
  --yellow-400: #ffee58;
  --yellow-500: #ffeb3b;
  --yellow-600: #fdd835;
  --yellow-700: #fbc02d;
  --yellow-800: #f9a825;
  --yellow-900: #f57f17;
  --yellow-a100: #ffff8d;
  --yellow-a200: #ffff00;
  --yellow-a400: #ffea00;
  --yellow-a700: #ffd600;

  /* AMBER */
  --amber-50: #fff8e1;
  --amber-100: #ffecb3;
  --amber-200: #ffe082;
  --amber-300: #ffd54f;
  --amber-400: #ffca28;
  --amber-500: #ffc107;
  --amber-600: #ffb300;
  --amber-700: #ffa000;
  --amber-800: #ff8f00;
  --amber-900: #ff6f00;
  --amber-a100: #ffe57f;
  --amber-a200: #ffd740;
  --amber-a400: #ffc400;
  --amber-a700: #ffab00;

  /* ORANGE */
  --orange-50: #fff3e0;
  --orange-100: #ffe0b2;
  --orange-200: #ffcc80;
  --orange-300: #ffb74d;
  --orange-400: #ffa726;
  --orange-500: #ff9800;
  --orange-600: #fb8c00;
  --orange-700: #f57c00;
  --orange-800: #ef6c00;
  --orange-900: #e65100;
  --orange-a100: #ffd180;
  --orange-a200: #ffab40;
  --orange-a400: #ff9100;
  --orange-a700: #ff6d00;

  /* DEEPORANGE */
  --deeporange-50: #fbe9e7;
  --deeporange-100: #ffccbc;
  --deeporange-200: #ffab91;
  --deeporange-300: #ff8a65;
  --deeporange-400: #ff7043;
  --deeporange-500: #ff5722;
  --deeporange-600: #f4511e;
  --deeporange-700: #e64a19;
  --deeporange-800: #d84315;
  --deeporange-900: #bf360c;
  --deeporange-a100: #ff9e80;
  --deeporange-a200: #ff6e40;
  --deeporange-a400: #ff3d00;
  --deeporange-a700: #dd2c00;

  /* BROWN */
  --brown-50: #efebe9;
  --brown-100: #d7ccc8;
  --brown-200: #bcaaa4;
  --brown-300: #a1887f;
  --brown-400: #8d6e63;
  --brown-500: #795548;
  --brown-600: #6d4c41;
  --brown-700: #5d4037;
  --brown-800: #4e342e;
  --brown-900: #3e2723;

  /* GREY */
  --grey-50: #fafafa;
  --grey-100: #f5f5f5;
  --grey-200: #eeeeee;
  --grey-300: #e0e0e0;
  --grey-400: #bdbdbd;
  --grey-500: #9e9e9e;
  --grey-600: #757575;
  --grey-700: #616161;
  --grey-800: #424242;
  --grey-900: #212121;

  /* BLUEGREY */
  --bluegrey-50: #eceff1;
  --bluegrey-100: #cfd8dc;
  --bluegrey-200: #b0bec5;
  --bluegrey-300: #90a4ae;
  --bluegrey-400: #78909c;
  --bluegrey-500: #607d8b;
  --bluegrey-600: #546e7a;
  --bluegrey-700: #455a64;
  --bluegrey-800: #37474f;
  --bluegrey-900: #263238;
}

span,
small,
div,
a,
p,
ul,
ol,
li,
label,
input,
textarea,
select,
option {
  font-family: var(--font-primary);
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-heading);
}
button {
  font-family: var(--font-primary-bold);
}

@keyframes toast-in-right {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes toast-in-left {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}
.pointer {
  cursor: pointer;
}
.bold {
  font-weight: bold;
}
.secondary-text {
  color: var(--color-secondary);
}
